import React, { useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { graphql } from 'gatsby';
import { Box } from 'grommet';

import { useStoryblokState } from '../lib/storyblok';
import { get, some } from '../lib/nodash';
import { enableFilter, disableFilter } from '../state/ui/uiSlice';
import usePageBodyComponents from '../components/DynamicPage/usePageBodyComponents';
import Seo from '../components/Seo';
import useMenuOffset from '../components/useMenuOffset';

const StoryblokPageTemplate = ({
  data: { storyblokEntry, site },
  location,
}) => {
  const canonicalPath =
    storyblokEntry.field_canonicalUrl_string &&
    storyblokEntry.field_canonicalUrl_string !== ''
      ? storyblokEntry.field_canonicalUrl_string
      : location.pathname;
  const canonicalUrl = `${site.siteMetadata.siteUrl}${canonicalPath}`;
  const story = useStoryblokState(storyblokEntry);
  const pageTitle =
    get('content.metafields.title', story) ||
    get('title', story) ||
    get('field_title_string', storyblokEntry);
  const dispatch = useDispatch();
  const offset = get('content.offsetForMenu', story);
  const components = usePageBodyComponents(story, {
    pageTitle,
    pageSlug: story.full_slug,
  });
  const { navHeight } = useMenuOffset();

  useEffect(() => {
    if (some({ component: 'PaintBrowser' }, get('content.body', story))) {
      dispatch(enableFilter());
    } else {
      dispatch(disableFilter());
    }
  }, [story]);

  return (
    <>
      <Seo
        title={pageTitle}
        canonical={canonicalUrl}
        description={
          get('content.metafields.description', story) ||
          `Backdrop's curated selection of ${
            get('title', story) || get('field_title_string', storyblokEntry)
          }.`
        }
        image={get('content.seoImage.filename', story)}
        noIndex={get('content.noIndex', story)}
      />
      <Box pad={{ top: offset ? `${navHeight}px` : 0 }}>{components}</Box>
    </>
  );
};

StoryblokPageTemplate.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
  serverData: PropTypes.object,
};

export default memo(StoryblokPageTemplate);

export const query = graphql`
  query StoryblokPaintCollectionPageContent($full_slug: String!) {
    storyblokEntry(full_slug: { eq: $full_slug }) {
      id
      name
      full_slug
      content
      field_title_string
      field_canonicalUrl_string
      internalId
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;
